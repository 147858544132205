.product {
  position: relative; }
  .product figure {
    position: relative;
    margin-bottom: 0; 
    min-height: 250px;}
    .product figure:before {
      display: none;
      position: absolute;
      content: "";
      z-index: 1;
      left: 10px;
      top: 10px;
      right: 10px;
      bottom: 10px;
      border: 2px solid #00E676;
      opacity: 1;
      visibility: visible;
      -webkit-transition: .3s all ease;
      -o-transition: .3s all ease;
      transition: .3s all ease; }
  .product h2 {
    font-size: 18px; }
  .product .bio-img {
    position: relative;
    text-align: center;
    overflow: hidden;
    margin-bottom: 30px; }
    .product .bio-img img {
      position: relative;
      -webkit-transition: .3s all ease-in-out;
      -o-transition: .3s all ease-in-out;
      transition: .3s all ease-in-out;
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1); }
    .product .bio-img .social {
      z-index: 2;
      padding: 10px 0;
      width: 100%;
      position: absolute;
      bottom: 0%;
      -webkit-transform: translateY(100%);
      -ms-transform: translateY(100%);
      transform: translateY(100%);
      left: 0;
      -webkit-transition: .3s all ease;
      -o-transition: .3s all ease;
      transition: .3s all ease;
      background: #00E676; }
      .product .bio-img .social a {
        display: inline-block;
        padding: 5px;
        color: #fff; }
  .product:hover img {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05); }
  .product:hover .social {
    bottom: 0;
    -webkit-transform: translateY(0%);
    -ms-transform: translateY(0%);
    transform: translateY(0%); }
  .product:hover figure:before {
    opacity: 0;
    visibility: hidden; }
